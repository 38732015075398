<template>
  <div id="kakao-map-con">
    <div id="map"></div>
    <div id="directions-con">
      <div class="addr">
        <p class="head">(주)탑스맨 주소</p>
        <p class="content">
          <span class="Hyphen">&#45;</span> 서울특별시 구로구 디지털로 272,
          403호(구로동,한신아이티타워)
        </p>
        <p class="content">Tel : 02-863-9153 / 02-863-0153</p>
        <p class="content">Fax : 02-863-9154</p>
      </div>
      <div class="subway">
        <p class="head">오시는길</p>
        <p class="content Line1">
          <span class="Hyphen">&#45;</span> 지하철 1호선 가산디지털단지역 3번
          출구에서 1.2km
        </p>
        <p class="content Line2">
          <span class="Hyphen">&#45;</span> 지하철 2호선 구로디지털단지역 3번
          출구에서 800m
        </p>
        <p class="content Line7">
          <span class="Hyphen">&#45;</span> 지하철 7호선 남구로역 2번 출구에서
          700m
        </p>
      </div>
      <div class="Parking">
        <p class="head">주차안내</p>
        <p class="content">
          <span class="Hyphen">&#45;</span> 한신아이티타워 지하 주차장 이용
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    window.kakao && window.kakao.maps
      ? this.initMap()
      : this.addKakaoMapScript()
  },
  methods: {
    addKakaoMapScript() {
      const script = document.createElement('script')
      script.onload = () => window.kakao.maps.load(this.initMap)
      script.src =
        '//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=a02ad9fbbc3a5be1e72082629b3aec86'
      document.head.appendChild(script)
    },

    async initMap() {
      var mapContainer = document.getElementById('map'), // 지도를 표시할 div
        mapOption = {
          center: new window.kakao.maps.LatLng(37.4824724, 126.894424), // 지도의 중심좌표
          level: 2, // 지도의 확대 레벨
        }

      var map = new window.kakao.maps.Map(mapContainer, mapOption) // 지도를 생성합니다

      // 마커를 표시할 위치와 title 객체 배열
      var positions = [
        {
          title: '한신 아이티타워',
          latlng: new window.kakao.maps.LatLng(37.4824724, 126.894424),
          content:
            '<div class ="label"><span class="left"></span><span class="center">(주)탑스맨</span><span class="right"></span></div>',
        },
      ]

      // 이미지 주소입니다
      var imageSrc = require('../assets/image/ico/mapPin.png')
      for (var i = 0; i < positions.length; i++) {
        // 마커 이미지의 이미지 크기 입니다
        var imageSize = new window.kakao.maps.Size(40, 56)

        // 마커 이미지
        var markerImage = new window.kakao.maps.MarkerImage(imageSrc, imageSize)

        // 마커
        var marker = new window.kakao.maps.Marker({
          map: map, // 마커를 표시할 지도
          position: positions[i].latlng, // 마커를 표시할 위치
          title: positions[i].title, // 마커의 타이틀, 마커에 마우스를 올리면 타이틀이 표시됩니다
          image: markerImage, // 마커 이미지
        })

        //TEXT
        marker.setMap(map)
        var infowindow = new window.kakao.maps.InfoWindow({
          position: positions[i].latlng,
          content: positions[i].content,
        })
        infowindow.open(map, marker)

        //클릭 이벤트
        window.kakao.maps.event.addListener(marker, 'click', function () {
          window.open(
            'https://map.kakao.com/?from=roughmap&eName=%ED%95%9C%EC%8B%A0%EC%95%84%EC%9D%B4%ED%8B%B0%ED%83%80%EC%9B%8C&eX=511151.0&eY=1041195.0',
          )
        })
        //컨트롤러
        //지도 / 스카이뷰
        var mapTypeControl = new window.kakao.maps.MapTypeControl()
        map.addControl(
          mapTypeControl,
          window.kakao.maps.ControlPosition.TOPRIGHT,
        )
        //Zoom
        var zoomControl = new window.kakao.maps.ZoomControl()
        map.addControl(zoomControl, window.kakao.maps.ControlPosition.RIGHT)
      }
    },
  },
  created() {},
}
</script>

<style>
#map {
  height: 500px;
  border: 3px solid #ef7c4a;
  border-radius: 1rem;
  box-shadow: 0px 4px 6px 0 rgba(0 0 0 / 10%);
}
#directions-con {
}
#map
  > div:nth-of-type(1)
  > div:nth-of-type(1)
  > div:nth-of-type(6)
  > div:nth-of-type(2) {
  border-radius: 1rem;
  border: 2px solid #090909 !important;
  width: 120px !important;
  background-color: #f8f8f8;
}
#map
  > div:nth-of-type(1)
  > div:nth-of-type(1)
  > div:nth-of-type(6)
  > div:nth-of-type(2)
  > div:nth-of-type(2) {
  width: 120px !important;
}
#map
  > div:nth-of-type(1)
  > div:nth-of-type(1)
  > div:nth-of-type(6)
  > div:nth-of-type(2)
  > div:nth-of-type(2)
  .label {
  text-align: center;
  width: 100%;
}
#map
  > div:nth-of-type(1)
  > div:nth-of-type(1)
  > div:nth-of-type(6)
  > div:nth-of-type(2)
  > div:nth-of-type(2)
  .label
  .center {
  color: #090909;
  font-weight: 500;
  line-height: 1.6;
}
</style>
