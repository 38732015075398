<template>
  <main class="intro">
    <div class="top_cont">
      <img src="~assets/image/main/intro_top.png" alt="" class="pc" />
      <img src="~assets/image/main/intro_top_m.png" alt="" class="m" />
      <div class="txt_box">
        <p class="t2">All About 탑스맨</p>
      </div>
    </div>
    <div class="sub_comp">
      <div class="cont __no1">
        <span class="compNum" id="CEO">1</span>
        <h2 class="title __no1">CEO 인사말</h2>
        <div class="in_cont">
          <img src="~assets/image/main/sub_comp_cont03_img.png" alt="" />
          <div class="text_box">
            <p class="t1">
              안녕하십니까 ! <br />
              (주)탑스맨의 대표이사 한윤덕입니다.
            </p>
            <p class="t2">
              저는 20년 이상 호텔에 인력을 공급하는 사업에 종사한 경험을
              바탕으로<br class="pc" />
              스마트 시대에 걸맞게 쉽고 편리한 호텔 구인구직을 위하여<br
                class="pc"
              />
              "호구 153"플랫폼(Platform)을 런칭하였습니다<br class="pc" />
              저희 플랫폼을 통하여 호텔과 호텔 근무를 희망하는 사람들<br
                class="pc"
              />
              그리고 저희 회사까지 윈윈(Win Win)할 수 있기를 바랍니다.
            </p>
          </div>
        </div>
      </div>
      <div class="cont __no2">
        <span class="compNum" id="MokeUp">2</span>
        <h2 class="title __no1">회사 소개</h2>
        <div class="in_cont">
          <img src="~assets/image/main/sub_comp_cont01_img.png" alt="" />
          <div class="text_box">
            <p class="t1">
              호텔 구인구직에 특화된 플랫폼 <br />"호구153"을 운영하고 있습니다.
            </p>
            <p class="t2">
              호텔은 호구153에 구인광고를 등록하여 <br />적시에 필요한 인력을
              채용할 수 있고 호텔 취업을 희망하는 개인들은 <br />근무요청을
              통하여 일자리를 제공받을 수 있습니다.<br />
              호구153을 이용하기 위해서는 구인자와 구직자 모두 <br />회원가입이
              필수이고 (주)탑스맨은 다양한 구인과 구직정보를 제공하여
              <br />구인자와 구직자의 만족을 추구하고 있습니다.
            </p>
          </div>
        </div>
      </div>
      <div class="cont __no3">
        <span class="compNum" id="History">3</span>
        <h2 class="title __no3">회사 연혁</h2>
        <div class="in_cont">
          <!-- <div
            id="historySlides"
            v-if="historySlides"
            class="history-slides swiper-container"
          >
            <ul class="history-con swiper-wrapper">
              <li
                class="swiper-slide"
                v-for="(history, idx) in historyList"
                :key="`history_${idx}`"
                @click="historyContent(idx)"
              >
                <div class="Item">
                  <div></div>
                  <span :class="{ on: historyNum === idx }"></span>
                  <p :class="{ on: historyNum === idx }">
                    {{ history.date }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div id="Ffiliates-con">
            {{ historyContentText }}
          </div> -->
          <div>
            <ul class="history-con">
              <li
                class="history-list"
                v-for="(history, idx) in historyBy"
                :key="`history_${idx}`"
              >
                <div class="Item">
                  <div class="line"></div>
                  <span class="circle"></span>
                </div>
                <div class="text">
                  <p class="d1">{{ history.date }}</p>
                  <p class="d2">
                    <span>{{ history.content }} </span>
                    <span>{{ history.affiliates }}</span>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- ----------------------------------------------------------------- -->
      <div class="cont __no4">
        <span class="compNum" id="Certified">4</span>
        <h2 class="title __no4">기업인증 / 특허 & 확인증</h2>
        <div class="in_cont">
          <div>
            <ul class="Certified-con">
              <li
                class="Certified-list"
                v-for="(Certified, idx) in CertifiedList"
                :key="`Certified${idx}`"
              >
                <div class="CertifiedImg-con">
                  <img class="CertifiedImg" :src="Certified.imgUrl" alt="" />
                </div>
                <p class="CertifiedText">{{ Certified.content }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- ----------------------------------------------------------------- -->

      <div class="cont __no5">
        <span class="compNum" id="directions">5</span>
        <h2 class="title __no5">오시는길</h2>
        <div class="in_cont">
          <kakao-map></kakao-map>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import kakaoMap from 'Views/kakaoMap'
import _ from 'lodash'
// import Swiper from 'swiper'
// import 'swiper/css/swiper.css'

export default {
  components: { kakaoMap },
  data() {
    return {
      historyList: [
        { date: '2019.12', content: '사업자등록', affiliates: '(구로세무서)' },
        {
          date: '2021.07',
          content: '통신판매업 신고',
          affiliates: '(구로구청)',
        },
        {
          date: '2021.09',
          content: '연구개발전담부서 인정',
          affiliates: '(한국산업기술진흥협회)',
        },
        {
          date: '2021.09',
          content: '호구(hogoo)캐릭터 저작권 등록',
          affiliates: '(한국저작권위원회)',
        },
        {
          date: '2021.09',
          content: '직업정보제공 사업 신고',
          affiliates: '(서울지방고용노동청)',
        },
        {
          date: '2021.12',
          content: '벤처기업 확인',
          affiliates: '(벤처기업협회)',
        },
        {
          date: '2022.03',
          content:
            '룸메이드 스마트매칭 서비스 제공 시스템, 방법 및 어플리케이션 특허 등록',
          affiliates: '(특허청)',
        },
        { date: '2022.03', content: '관광사업 등록', affiliates: '(구로구청)' },
      ],
      CertifiedList: [
        {
          imgUrl: require('../assets/image/main/CertifiedList_05.png'),
          content: '사업자 등록증',
        },
        {
          imgUrl: require('../assets/image/main/CertifiedList_01.png'),
          content: '벤처기업 확인서',
        },
        {
          imgUrl: require('../assets/image/main/CertifiedList_02.png'),
          content: '연구개발전담부서 인정서',
        },
        {
          imgUrl: require('../assets/image/main/CertifiedList_04.png'),
          content: '특허증',
        },
        {
          imgUrl: require('../assets/image/main/CertifiedList_09.png'),
          content: '근로자파견사업 허가증',
        },
        {
          imgUrl: require('../assets/image/main/CertifiedList_07.png'),
          content: '직업정보제공사업신고 확인증',
        },
        {
          imgUrl: require('../assets/image/main/CertifiedList_06.png'),
          content: '관광사업 등록증',
        },
        {
          imgUrl: require('../assets/image/main/CertifiedList_03.png'),
          content: '저작권 등록증',
        },
      ],
      historySlides: true,
      historyNum: null,
      historyContentText: '',
      historyaFfiliatesText: '',
    }
  },
  computed: {
    historyBy: function () {
      return _.reverse(this.historyList)
    },
  },
  watch: {
    // historyList(historyList) {
    //   if (historyList) {
    //     this.initSwiper()
    //   }
    // },
    // historyContentText() {
    //   this.historyContentText = this.historyContent()
    // },
  },
  methods: {
    // initSwiper() {
    //   this.$nextTick(() => {
    //     if (this.historySlides) {
    //       this.historySlides = new Swiper('#historySlides', {
    //         slidesPerView: 8,
    //         // loop: true,
    //         direction: 'horizontal',
    //         // autoplay: {
    //         //   delay: 3000,
    //         //   disableOnInteraction: true,
    //         // },
    //         breakpoints: {
    //           1: {
    //             slidesPerView: 4.5, //브라우저가 768보다 클 때
    //           },
    //           768: {
    //             slidesPerView: 8, //브라우저가 768보다 클 때
    //           },
    //         },
    //       })
    //     }
    //   })
    // },
    setMapType() {
      alert('a')
    },
    historyContent(idx) {
      this.historyContentText =
        this.historyList[idx].content + ` - ` + this.historyList[idx].affiliates
      this.historyNum = idx
    },
  },
  // created() {
  //   this.initSwiper()
  // },
}
</script>

<style></style>
